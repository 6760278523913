import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get("user", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getBankHolidays(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getBankHolidays", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    deleteBankHoliday(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteBankHoliday/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /********* Roles */
    /*********** */
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("roles", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("permissions", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("roles/store", role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    // TASKS

    getAllTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getAllTasks", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getTaskById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getTaskById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTask(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateTask/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteTask(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteTask/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTask(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveTask", metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // DEPARTMENT

    getAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getAllDepartments", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getDepartmentById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getDepartmentById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateDepartment(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateDepartment/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteDepartment(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteDepartment/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveDepartment(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveDepartment", metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //SITES

    getAllSites(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getAllSites", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getRoles", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getSiteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getSiteById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSite(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSite/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSite(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`deleteSite/${metData.id}`, metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSite(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveSite", metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllTimelineActions(ctx, metData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getAllTimelineActions", metData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
